export default [{
  header: 'confSuperuser',
},

{
  title: 'Superusuario',
  icon: 'DatabaseIcon',

  children: [
    {
      title: 'Tokens',
      route: 'tokens',
      icon: 'KeyIcon',
    },
    {
      title: 'Almacenamiento',
      route: 'bucket',
      icon: 'CloudIcon',
    },
    {
      title: 'Publicidad',
      route: 'ads',
      icon: 'MonitorIcon',
    },
    {
      title: 'modelo',
      route: 'service',
      icon: 'PackageIcon',
    },
    {
      title: 'Directos',
      route: 'live',
      icon: 'RadioIcon',
    },
    {
      title: 'transcoding.transcode',
      route: 'transcoding',
      icon: 'FilmIcon',
    },
    {
      title: 'Correo',
      route: 'mail',
      icon: 'MailIcon',
    },
    {
      title: 'Plantillas',
      route: 'templates',
      icon: 'FileTextIcon',
    },
    {
      title: 'sistemForm',
      route: 'verificator',
      icon: 'UserCheckIcon',
    },
    {
      title: 'Plataformas',
      route: 'create-platform',
      icon: 'FilePlusIcon',
    },
  ].sort((a, b) => a.title.localeCompare(b.title)),
},
]
