export default [
  {
    title: 'Códigos promocionales',
    icon: 'DollarSignIcon',

    children: [
      {
        title: 'codes',
        route: 'promotional-codes',

      },
      {
        title: 'uses',
        route: 'use-codes',

      },
    ],
  },
]
