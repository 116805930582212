<template>
  <b-card id="platformSelector">
    <b-row class="mb-2">
      <b-col md="4">
        <h1>{{ $t('Plataformas') }}</h1>
        <h5 class="text-primary">
          {{ $t('selectPlat') }}
        </h5>
      </b-col>
    </b-row>

    <b-row
      cols="12"
      class="mb-2"
    >
      <b-col>
        <b-form-input
          id="search_platform"
          :placeholder="$t('Search')"
          autofocus
          @change="busqueda"
        />
      </b-col>
      <b-col>
        <!-- <b-button variant="success" @click="seleccionar">Seleccionar</b-button> -->
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @input="fetchData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="platform in platforms"
        :id="platform.node.id"
        :key="platform.node.id"
        v-b-tooltip.hover.top="platform.node.name"
        cols="3"
      >
        <b-card
          id="img"
          class="cursor-pointer text-center mb-2 embed-responsive embed-responsive-16by9"
          @click="onRowSelected(platform)"
        >
          <b-img
            rounded="lg"
            :src="buildImageUrl(platform)"
            style="object-fit: contain;"
            class="embed-responsive-item"
          />
        </b-card>
        {{ platform.node.displayName }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {
  BPagination,
  BCard,
  BCol,
  BRow,
  BImg,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { clientNode } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BImg,
    BPagination,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      videojs: null,
      video: false,
      fileUrl: null,
      name: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      platforms: null,
      currentParentFolder: null,
      tableColumns: [
        { key: 'logo', label: 'Logo' },
        { key: 'platform', label: 'Plataforma' },
      ],
      perPage: 12,
      currentPage: 1,
      rows: null,
      buscar: null,

    }
  },

  mounted() {
    this.fetchData()
  },
  methods: {
    seleccionar() {
      this.$emit('client', this.selected)
    },
    onRowSelected(data) {
      this.selected = data.node
      this.seleccionar()
    },
    buildImageUrl(folder) {
      return folder.node.logo == null || folder.node.logo.length === 0
        ? noCover
        : folder.node.logo
    },

    fechasSplit(value) {
      try {
        const fecha = value
        const nueva = fecha.split(' ')[0]
        const format = nueva.split('-')
        return `${format[2].split('T')[0]}-${format[1]}-${format[0]}`
      } catch (error) {
        return null
      }
    },
    fetchData() {
      axios
        .post('', {
          query: `
         query {
                allClients(
                  first:${this.perPage},
                  offset:${this.perPage * (this.currentPage - 1)},
                  ${this.buscar != null ? `name_Icontains: "${this.buscar}",` : ''}                 
                  
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      ${clientNode()}
                    }
                  }
                  }
                }
        `,
        })
        .then(response => {
          messageError(response, this)

          const clients = response.data.data.allClients.edges
          this.platforms = clients
          this.rows = response.data.data.allClients.totalCount
        }).catch(() => {
        })
    },

    busqueda(data) {
      this.buscar = data
      this.fetchData()
    },

  },

}
</script>

<style lang="scss">
#avatar {
  width: 10rem;
  height: 5.5rem;
}

#contentsSelector .td {
  width: 50px;
  height: 100px;
}

#contentsSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#contentsSelector .dropContainer {
  border: 3px dashed;
}

#contentsSelector .card-width {
  width: 200px;
}

#contentsSelector .list-group-item {
  transition: all 1s;
}
</style>
