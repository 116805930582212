<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData ? (userData.fullName || userData.username) : 'User' }}
        </p>
        <span class="user-status">{{ userData ? userData.role : 'Guest' }}</span>
      </div>
      <b-avatar size="40" :src="userData ? userData.avatar : ''" variant="light-primary" badge class="badge-minimal"
        badge-variant="success">
        <feather-icon v-if="!userData || !userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>


    <b-dropdown-item v-if="userData && userId" :disabled="isSuperuser"
      :to="{ name: 'account-setting', params: { id: userId } }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>{{ $t('Profile') }}</span>
    </b-dropdown-item>

    <!-- Idioma -->
    <b-dropdown-divider />
    <locale />
    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t('logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Locale from './Locale.vue'
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { getUserData } from "@/auth/utils";
import { mapActions } from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
  },
  data() {
    return {
      userData: getUserData(),
      avatarText,
      isSuperuser: false,
      userId:null,
    };
  },
  mounted() {
    this.isSuperuser = getUserData()?.isSuperuser
    this.userId = getUserData()?.id
  },
  methods: {
    ...mapActions('verticalMenu', ['cleanData']),

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      this.cleanData(null)

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });

    },

  },
};
</script>
