<template>
  <b-dropdown id="sub-dropdown-lang" variant="link" right>
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" class="align-items-center"
      @click="idioma(localeObj.locale)">
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ $t(localeObj.locale) }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdownItem, BImg, BDropdown } from "bootstrap-vue";

export default {
  components: {
    BDropdownItem,
    BDropdown,
    BImg,
  },
  data() {
    return {
      isSubDropdownLangVisible: false
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'sub-dropdown-lang') {
        this.isSubDropdownLangVisible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'sub-dropdown-lang') {
        this.isSubDropdownLangVisible = false;
      }
      if (this.isSubDropdownLangVisible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    idioma(local) {
      localStorage.setItem("idioma", local);
      this.$i18n.locale = local;
      // location.reload();

      this.$router.go(0);
    },

    getNavigatorLanguage() {
      const navigatorLanguage = navigator.language || navigator.userLanguage;
      // Verificar si el idioma es español o alguna variante
      if (navigatorLanguage.startsWith('es')) {
        return 'es';
        // Verificar si el idioma es italiano o alguna variante
      } else if (navigatorLanguage.startsWith('it')) {
        return 'it';
      } else {
        return 'en';
      }
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      if (localStorage.getItem("idioma")) {
        this.$i18n.locale = localStorage.getItem("idioma");
      } else {
        this.$i18n.locale = this.getNavigatorLanguage()
      }
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  setup() {
    const locales = [
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Castellano",
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "it",
        img: require("@/assets/images/flags/it.png"),
        name: "Italiano",
      },
    ];

    return {
      locales,
    };
  },
};
</script>

<style></style>
